import logo from './logo.svg';
import './App.css';
import { HashRouter as Router,Route, Routes } from 'react-router-dom';

import Home from './page/home';
import Signup from './page/sign-up';
import Login from './page/login';
import Listpage from './page/list-page';
import CourseDetail from './page/course-detail';
import MyLIst from './page/My-list';
import ClassDetails from './page/class-detail';
import MyCourseList from './component/myCourseList/MyCourseList';
import MyProfile from './page/myProfile';
import PageNotFound from './component/pagenotfound';
function App() {

  // const handleContextMenu = (event) => {
  //   event.preventDefault(); // Prevent the default right-click menu
  //   console.log("Right-click is disabled on the whole app!");
  // };

  return (
    // <div className="App" onContextMenu={handleContextMenu}>
    <div className="App" >
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="signup" element={<Signup />} />
        <Route path="my-profile" element={<MyProfile />} />
        <Route path="login" element={<Login />} />
        <Route path="online-course" element={<Listpage />} />
        <Route path="course-detail" element={<CourseDetail />} />
        <Route path="MyLIst" element={<MyLIst />} />
        <Route path="premium-video-courses-detail" element={<ClassDetails />} />
        <Route path="my-course-list" element={<MyCourseList />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
}

export default App;
