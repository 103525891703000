// src/utils/api.js
import axios from 'axios';

// A utility function to handle API calls
export const apiCall = async (url, method = 'POST', body = null) => {
  try {
    // Configure the request options

    const userToken = localStorage.getItem('auth-key');
    const user = userToken ? userToken : null;


    const options = {
      method: method,
      url: "https://uplatz.com/uplatz-new/api/" + url,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': user ? `Bearer ${user}` : ""
      },
      data: body,
    };

    // Make the API call using axios
    const response = await axios(options);

    // Return the data and no error
    return { data: response.data, error: null };

  } catch (error) {
    // Return null data and the error
    return { data: null, error: error.response ? error.response.data : error.message };
  }
};
