import { render, screen } from "@testing-library/react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Container, Row, Col, Table } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import dataScience from "../../img/data-analysis.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import Form from "react-bootstrap/Form";
import courseImg from "../../img/course-img.jpg";
// import courseImg from '../img/course-img.jpg';
import Pagination from "react-bootstrap/Pagination";
import Header from "../header";
import Footer from "../footer";
import { Link, useNavigate } from "react-router-dom";
import useApi from "../../Hooks/useApi";
import { useEffect, useState } from "react";
import { PICURL } from "../../utils/helper";
import MyPagination from "../MyPagination";
import LoaderComponent from "../loader";
// import {} from "react-router-dom"
// import { useHistory } from 'react-router-dom';

const MyCourseList = () => {
  let navigate = useNavigate();
  // const history = useHistory();

  const { data, error, loading, makeApiCall } = useApi(
    "my-courses",
    "POST",
    []
  );

  const [couserData, setCourseData] = useState([]);

  useEffect(() => {
    // Make the API call
    makeApiCall({ is_premium: 1 });
  }, []); // Only run once on component mount

  // Handle data update when `data` changes
  useEffect(() => {
    if (data) {
      setCourseData(data?.data);
    }
    console.log("data : ", data, ", error :", error, ", loading : ", loading);
  }, [data, error, loading]); // Run this effect when data, error, or loading change

  const handleCourseDetail = (course) => {
    navigate("/premium-video-courses-detail", {
      state: {
        course_id: course?.group_id,
      },
    });
  };

  const handlePageChange = (page) => {
    // Fetch data for the selected page
    makeApiCall({ is_premium: 1, page }); // Call your API with the new page number
  };

  return (
    <>
      <Container fluid>
        <div classNavme="BetList">
          <Header />
        </div>

        <div className="GreyBG py-5">
          <Container>
            <Row className="align-items-center">
              <Col xs={12} md={12} lg={10}>
                <div className="cat-icon d-inline-block">
                  <img src={dataScience} style={{ maxWidth: "50px" }} />
                </div>
                <div className="cat-text d-inline-block">
                  <h2 className="blue">My Course Data</h2>
                  <p>
                    Build expertise in data streaming, business analytics, data
                    visualization, data analysis, data science programming, data
                    engineering, data architecture, predictive analytics, and
                    more.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <Container className="py-5">
          <Row className="align-items-center">
            <Col xs={12} md={9}>
              <h2 className="f-20 fw-700 text-uppercase">
                All Courses Related
              </h2>
              <p>
                <span className="results">{couserData?.total} Results</span>
                <span className="ratings">
                  4.7
                  <span className="stars">
                    <FontAwesomeIcon icon={faStar} />
                    <FontAwesomeIcon icon={faStar} />
                    <FontAwesomeIcon icon={faStar} />
                    <FontAwesomeIcon icon={faStar} />
                    <FontAwesomeIcon icon={faStar} />
                    <span>(1065 Reviews)</span>
                  </span>
                </span>
              </p>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col xs={12} md={12}>
              {loading ? (
                <LoaderComponent loader={loading} />
              ) : (
                <ul
                  className="text-start productList list-page my-courses mb-3"
                  style={{ cursor: "pointer" }}
                >
                  {couserData?.data?.length > 0 ? (
                    couserData?.data?.map((course) => {
                      return (
                        <>
                          <li onClick={() => handleCourseDetail(course)}>
                            <div className="imgs">
                              <img
                                src={PICURL + course?.picture}
                                style={{ maxWidth: "100%" }}
                              />

                              <span className="tag">Courses</span>
                            </div>
                            <h4>{course?.group_name}</h4>
                            <p className="author">2,486,255 Viewers</p>
                            <p className="ratings">
                              4.7
                              <span className="stars">
                                <FontAwesomeIcon icon={faStar} />
                                <FontAwesomeIcon icon={faStar} />
                                <FontAwesomeIcon icon={faStar} />
                                <FontAwesomeIcon icon={faStar} />
                                <FontAwesomeIcon icon={faStar} />
                                <span>({course?.total_review} Reviews)</span>
                              </span>
                            </p>
                            <p className="price">${course?.price}</p>
                          </li>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <li></li>
                      <li>
                        <h3>No Course</h3>
                      </li>
                    </>
                  )}
                </ul>
              )}

              <MyPagination
                current_page={couserData?.current_page}
                last_page={couserData?.last_page}
                next_page_url={couserData?.next_page_url}
                prev_page_url={couserData?.prev_page_url}
                onPageChange={handlePageChange}
              />
              {/* <Pagination className='text-center'>

                                <Pagination.Prev />
                                <Pagination.Item>{1}</Pagination.Item>

                                <Pagination.Item>{2}</Pagination.Item>
                                <Pagination.Item>{3}</Pagination.Item>
                                <Pagination.Item active>{4}</Pagination.Item>
                                <Pagination.Item>{5}</Pagination.Item>

                                <Pagination.Next />

                            </Pagination> */}
            </Col>
          </Row>
        </Container>

        <div classNavme="BetList">
          <Footer />
        </div>
      </Container>
    </>
  );
};

export default MyCourseList;
