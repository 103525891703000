import DOMPurify from "dompurify";
import parse from 'html-react-parser';
import { Accordion } from 'react-bootstrap'; 
export const PICURL = "https://training.uplatz.com/";
export const VIDEOURL = "https://training.uplatz.com/premium_video/";

export const stripHtml = (html) => {
  // Create a new DOM element
  const tempDiv = document.createElement("div");
  // Set the HTML content
  tempDiv.innerHTML = html;
  // Extract and return the text content
  return tempDiv.textContent || tempDiv.innerText || "";
};

export const currencySymbols = {
  USD: "$", // US Dollar
  EUR: "€", // Euro
  CRC: "₡", // Costa Rican Colón
  GBP: "£", // British Pound Sterling
  ILS: "₪", // Israeli New Sheqel
  INR: "₹", // Indian Rupee
  JPY: "¥", // Japanese Yen
  KRW: "₩", // South Korean Won
  NGN: "₦", // Nigerian Naira
  PHP: "₱", // Philippine Peso
  PLN: "zł", // Polish Zloty
  PYG: "₲", // Paraguayan Guarani
  THB: "฿", // Thai Baht
  UAH: "₴", // Ukrainian Hryvnia
  VND: "₫", // Vietnamese Dong
  AED: "د.إ", // United Arab Emirates
  IDR: "Rp", // Indonesian Rupiah
  SGD: "S$", // Singapore dollar
  MYR: "RM", // Malaysian Ringgits
  AUD: "a$", // Australian Dollar
};

export const ContentDisplay = ({ content }) => {
  const sanitizedContent = DOMPurify.sanitize(content); // Sanitize the content
  return <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />;
};

export const DynamicAccordionFromHtml = ({ htmlContent }) => {
  // Function to process and extract accordion items dynamically
  const processHtmlToAccordion = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html'); // Parse the HTML content into a DOM object
    const paragraphs = Array.from(doc.body.querySelectorAll('p')); // Get all <p> tags

    let accordionItems = [];
    let currentHeader = null;
    let currentBody = [];

    paragraphs.forEach((p) => {
      const isHeader = p.querySelector('strong'); // Check if <strong> tag exists inside <p>

      if (isHeader) {
        // If we have a current header and body, push the item to the accordion array
        if (currentHeader && currentBody.length > 0) {
          accordionItems.push({ header: currentHeader, body: currentBody });
          currentBody = []; // Reset body for the next item
        }
        // Set the new header (text inside <strong>)
        currentHeader = p.innerText;
      } else {
        // Add the content to the current body
        currentBody.push(p.innerHTML);
      }
    });

    // Push the last item after the loop
    if (currentHeader && currentBody.length > 0) {
      accordionItems.push({ header: currentHeader, body: currentBody });
    }

    return accordionItems;
  };

  const accordionItems = processHtmlToAccordion(htmlContent);
  return (
    <Accordion>
      {accordionItems.map((item, index) => (
        <Accordion.Item eventKey={index.toString()} key={index}>
          <Accordion.Header>{item.header}</Accordion.Header>
          <Accordion.Body>
            {item.body.map((content, i) => (
              <div key={i}><ContentDisplay content={content} /></div>
            ))}
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

export const DynamicAccordionFromHtml2 = ({ htmlContent }) => {
  // Function to process and extract accordion items dynamically
  const processHtmlToAccordion = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html'); // Parse the HTML content into a DOM object
    const elements = Array.from(doc.body.childNodes); // Get all child nodes

    let accordionItems = [];
    let currentHeader = null;
    let currentBody = [];

    elements.forEach((element) => {
      if (element.nodeType === 1 && element.tagName === 'B') {
        // If we already have a header and body, push it to accordionItems
        if (currentHeader && currentBody.length > 0) {
          accordionItems.push({ header: currentHeader, body: currentBody });
          currentBody = []; // Reset the body for the next accordion item
        }
        // Set the new header
        currentHeader = element.innerText;
      } else if (element.nodeType === 1 || element.nodeType === 3) {
        // Add the content to the current body (handling both elements and text nodes)
        currentBody.push(element.outerHTML || element.textContent);
      }
    });

    // Push the last item after the loop
    if (currentHeader && currentBody.length > 0) {
      accordionItems.push({ header: currentHeader, body: currentBody });
    }

    return accordionItems;
  };

  const accordionItems = processHtmlToAccordion(htmlContent);
  return (
    <Accordion>
      {accordionItems.map((item, index) => (
        <Accordion.Item eventKey={index.toString()} key={index}>
          <Accordion.Header>{item.header}</Accordion.Header>
          <Accordion.Body>
            {item.body.map((content, i) => (
              <div key={i}><ContentDisplay content={content} /></div>
            ))}
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

export const chunkArray = (array, size) => {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
};

export const sortList = {
  "Sort A-Z": "Sort A-Z",
  "Sort Z-A": "Sort Z-A",
  "Number of Enrolments": "Number of Enrolments",
  "Discount": "Discount",
  "Rating": "Rating",
  "Price : Low-to-High": "Price : Low-to-High",
  "Price : High-to-Low": "Price : High-to-Low"
};

/**
 * Function to convert currency from one type to another using local JSON files.
 * @param {string} currencyFrom - The original currency code (e.g., 'usd').
 * @param {number} currencyInput - The amount to be converted.
 * @return {Promise<string>} - The converted currency value as a string.
 */
export const currencyFromTo = async (currencyFrom, currencyInput) => {
  try {
      // Check if currency is already set in sessionStorage (fallback to INR)
      let currencyTo = localStorage.getItem('currency_code') || 'INR';
      currencyTo = currencyTo.toLowerCase();
      currencyFrom = currencyFrom.toLowerCase();

      // If the currencies are the same, return the amount
      if (currencyFrom === currencyTo) {
          return currencyTo.toUpperCase()+" "+Math.ceil(currencyInput);
      }

      // Build the file path based on the currency_from value
      const file = `${currencyFrom}_currency.json`;
      const url = `/currency_rate/${file}`;  // This assumes files are in the public/currency_rate folder

      // Use fetch to get the file contents
      const response = await fetch(url);
      if (!response.ok) {
          throw new Error('Could not fetch currency data');
      }

      // Parse the JSON file
      const currencyData = await response.json();

      // Calculate the converted amount if currency data is found
      if (currencyData[currencyTo]) {
          const convertedAmount = currencyData[currencyTo] * currencyInput;
          return currencyTo.toUpperCase()+` `+Math.ceil(convertedAmount);
      }

      // If no conversion rate is found, return the input currency and amount
      return currencyFrom.toUpperCase()+` `+Math.ceil(currencyInput);
  } catch (error) {
      console.error('Error fetching currency conversion data:', error);
      // On error, return the original currency and input amount
      return currencyFrom.toUpperCase()+` `+Math.ceil(currencyInput);
  }
};