import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import logo from '../img/uplatz-logo.png';
import InputGroup from 'react-bootstrap/InputGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faMagnifyingGlass, faCartPlus} from '@fortawesome/free-solid-svg-icons';
import {faBell, faHeart } from '@fortawesome/free-regular-svg-icons';
import { Link, useNavigate } from "react-router-dom";

import props from 'prop-types';
function HeaderIn() {
  const navigate = useNavigate()
  const useData = localStorage.getItem('user-data');
  const user = useData ? JSON.parse(useData) : null;

  const HandleLogout = () => {
    localStorage.clear('/')
    navigate('/login')
  }


    return (
        <header className="header">
         
    <Navbar expand="lg">
      <Container fluid>
        <Navbar.Brand ><Link to="/"><img src={logo} className="App-logo" alt="logo" /></Link></Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
          >
              <NavDropdown title="Categories" id="navbarScrollingDropdown">
              <NavDropdown.Item><Link to="#">Categories</Link></NavDropdown.Item>
              <NavDropdown.Item><Link to="/online-course">Online Course</Link></NavDropdown.Item>
                <NavDropdown.Divider />
              </NavDropdown>
          </Nav>
          <Form className="d-flex flex-grow-1">
          <InputGroup>
        <InputGroup.Text ><FontAwesomeIcon icon={faMagnifyingGlass} /></InputGroup.Text>
        <Form.Control
          placeholder="Search"
          aria-label="Username"
          aria-describedby="basic-addon1"
        />
      </InputGroup>
          </Form>
          <div className="d-lg-flex ms-auto my-3 my-lg-0">
          <Nav>
          <Nav.Link href="#deets">All Courses</Nav.Link>
            <Nav.Link eventKey={2} href="#memes">Career Path</Nav.Link>
            <Nav.Link eventKey={2} href="#memes">Bundles</Nav.Link>
            <Nav.Link eventKey={2} href="#memes">Subscription</Nav.Link>
            <Nav.Link eventKey={2} href="#memes">Uplatz Business</Nav.Link>
          </Nav>
          <div className='userIcons'>
            <span><FontAwesomeIcon icon={faHeart} /></span>
            <span><FontAwesomeIcon icon={faCartPlus} /></span>
            <span><FontAwesomeIcon icon={faBell} /></span>
            <Dropdown className="user d-inline-block">
            {useData ?
                <>
                  <Dropdown.Toggle  id="dropdown-basic" align="end">{user?.data.fullname}</Dropdown.Toggle>
                </>
                :
                <></>
              }
                <Dropdown.Menu align="end">
                  <Dropdown.Item as={Link} to="/my-profile">My Profile</Dropdown.Item>
                  <Dropdown.Item as={Link} to="/my-course-list">My course</Dropdown.Item>
                  <Dropdown.Item  href="/my-course-list">My course</Dropdown.Item>
                  <Dropdown.Item onClick={HandleLogout} >LogOut</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
          </div>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
      </header>
    );
}


export default HeaderIn;